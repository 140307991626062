<template>
<div>
    <div class = "grid grid-cols-1 md:grid-cols-2 mx-2 md:mx-32 my-6 px-4 md:px-8 py-4 md:py-6 portfolioBg">
        <div class = "flex flex-col content-center text-center m-auto">
            <h1 v-if="img !==''" class = "text-xl md:text-3xl text-stormy-darkGreen font-bold"> {{title}} </h1>
            <h1 v-else class = " flex text-xl md:text-3xl text-stormy-darkGreen font-bold justify-center text-center"> {{title}} </h1>

            <h2 v-if="img !==''" class = "text-opacity-75 text-stormy-green text-xs italic"> {{useList}} </h2>
            <h1 v-else class = " text-opacity-75 text-stormy-green text-xs italic"> {{useList}} </h1>

            <h2 v-if="img !==''" class = "text-opacity-75 text-stormy-green mb-2 text-xs italic"> {{roleList}} </h2>
            <h1 v-else class = " text-opacity-75 text-stormy-green text-xs italic"> {{roleList}} </h1>

            <img v-if="img !==''" class = "aspect-w-16 aspect-h-9 mx-auto flex content-center bg-stormy-darkGreen w-4/5 p-1 rounded-md text-stormy-lightGreen" v-bind:src = "'/img/' + img" alt = "Image Not Found"/>
        </div>
        <div class = "portfolioInnerBg px-4 md:px-6 py-4 md:py-8 m-auto">
            <h1 class = "text-sm md:text-xl text-stormy-lightGreen font-bold text-left">what is it?</h1>
            <p class = "mt-1 mb-3 text-xs md:text-sm text-justify text-stormy-lightGreen">{{whatDesc}} </p>
            <h1 v-if="learnDesc !== null" class = "text-sm md:text-xl text-stormy-lightGreen font-bold text-left">what did I learn?</h1>
            <ul v-if="learnDesc !== null" class = "mt-1 mb-3 text-xs md:text-sm text-justify text-stormy-lightGreen list-disc px-2 md:px-4">
                <li class = "mt-1" v-for="lesson in this.learnDesc" :key="lesson">
                    {{lesson}}
                </li>
                </ul>
            <a v-if = "link !== null" :href = "link" class = "flex italic text-xs text-stormy-orange text-left hover:text-stormy-beige">project link </a>
        </div>
        </div>
</div>
</template>

<script>
export default {
        name: 'portfolioPiece',
        props: ['jsonPath'],
        data(){
            return{
                title: "noTitle", whatDesc:null, learnDesc:[], img: null, link: null, using:[], roles: [],
            };
        },
        computed: {
            useList: function(){
                const lowerCase = []
                this.using.forEach(element => {
                    lowerCase.push(element.toLowerCase())
                });
                return "using- " + lowerCase.join(", ")
            },
            roleList: function(){
                const lowerCase = []
                this.roles.forEach(element => {
                    lowerCase.push(element.toLowerCase())
                });
                if(this.roles.length > 1)
                    return "roles- " + lowerCase.join(", ")
                else
                    return "role- " + lowerCase.join(", ")
            }
        },
        mounted() {
        setTimeout(
            function(){
                var replacers = document.querySelectorAll('[data-replace]');
                for(var i=0; i<replacers.length; i++){
                    let replaceClasses = JSON.parse(replacers[i].dataset.replace.replace(/'/g, '"'));
                    Object.keys(replaceClasses).forEach(function(key) {
                        replacers[i].classList.remove(key);
                        replacers[i].classList.add(replaceClasses[key]);
            });}
            }, 1);

            fetch('./json/portfolioData/' + this.jsonPath)
            .then(response => response.json())
            .then(data => {
                this.title = data.title
                this.using = data.using
                this.roles = data.roles
                this.whatDesc = data.whatDesc
                this.img = data.img
                this.link = data.link
                this.learnDesc = data.learnDesc
                });
            }
        }
</script>

<style scoped>

</style>