<template>
<div class = "overflow-hidden overflow-y-hidden">
    <div ref = 'home'>
        <homeSegment></homeSegment>
    </div>
    <div ref = 'gameDev' class = "mt-12 md:mt-24">
        <gamedev/>
    </div>
    <div ref = 'design' class = "mt-12 md:mt-24">
        <design/>
    </div>
    <div ref = 'skills' class = " mt-12 md:mt-24">
        <skills/>
    </div>
</div>
</template>

<script>
import design from './designPortfolioPage.vue'
import homeSegment from '@/components/homeSegment.vue'
import skills from './skillsPage.vue'
import gamedev from './gamePortfolioPage.vue'
export default {
    components:{
        homeSegment,
        skills,
        gamedev,
        design
    }
}
</script>