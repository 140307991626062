<template>
<div>
  <div v-for="message in errorMessage" :key="message">
    <p class = "font-sm text-sm text-stormy-orange">{{message}}</p>
  </div>
    <form class = "mt-4 px-4 py-4 bg-stormy-lightGreen rounded-lg shadow-lg mb-4 md:mb-32 mx-4 md:mx-56 content-center" 
    @submit.prevent="handleSubmit" name="contact" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
        <input type="hidden" name="form-name" value="contact" />
    <div class = "py-2 md:py-6 px-0 md:px-24 grid-rows-5 m-auto">
    <p class = "mb-2 md:mb-4">
    <label class = "contactText">*Name:&nbsp;&nbsp;</label>
    <input class="resize-none contactBox" name="name" v-model="form.name" />
    </p>
    <p class = "mb-2 md:mb-4">
    <label class = "contactText">*Email:&nbsp;&nbsp;</label> 
    <input class="resize-none contactBox" v-model="form.email" name="email"/>
    </p>
    <p class = "mb-2 md:mb-4">
    <label class = "contactText">Subject:&nbsp;&nbsp;</label>
    <input class="resize-none contactBox" v-model="form.subject" name="subject">
    </p>
    <p class = "mb-2 md:mb-4">
    <label class = "contactText">*Message:&nbsp;&nbsp;</label>
    <textarea class="resize-y contactBox" rows="4" v-model="form.message" name="message"></textarea>
    </p>
    <button class = "bg-stormy-orange mt-4 py-2 px-4 m-auto content-center text-stormy-maroon hover:text-stormy-beige hover:bg-stormy-maroon hover:scale-125 transform transition duration-200" type="submit">Send</button>
    </div>
</form>
</div>
</template>

<script>
import axios from "axios";
import router from "@/router/"
export default {
  name: "contactSegment",
  data: () => ({
  errorMessage: [],
  form: {
      name:'',
      email:'',
      subject:'',
      message:''
  }
  }),
  methods: {
    validateEmail(inputText)
    {
      var mailformat = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if(inputText.match(mailformat))
        return true;
      else
        return false;
    },
    encode (data) {
    return Object.keys(data)
        .map(
        key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
        )
        .join("&");
    },
    handleSubmit () {
      if(this.form.name === '' || !this.validateEmail(this.form.email) || this.form.message === '')
        {
          var newErrorMessage = [];
        console.log("error found");
        if(this.form.name === '')
          newErrorMessage.push('Please input a valid name.');
        if(!this.validateEmail(this.form.email))
          newErrorMessage.push('Please input a valid email (sam@example.com).');
        if(this.form.message === '')
          newErrorMessage.push(' Please input a message.');
        this.errorMessage = newErrorMessage;
      }
      else{
      const axiosConfig = {
        header: { "Content-Type": "application/x-www-form-urlencoded" }
      };
      axios.post(
        "/",
        this.encode({
          "form-name": "contact",
          ...this.form
        }),
        axiosConfig
      ).then(
          router.push({name: "ThankYou"}), 
      (error) => {
        if(error.response !== 429)
          router.push({name: "Sorry"});
        else   
          router.push({name: "ThankYou"});
      });
      }
    }
  }
}
</script>
