<template> 

<h1 class = "text-4xl md:text-6xl font-bold text-center text-stormy-maroon rightTransitionIn mt-12 md:mt-12 mb-4"
data-replace = "{'translate-x-56': 'translate-x-0', 'opacity-0': 'opacity-100'}"> DESIGN </h1>
<portfolioSegment jsonPath= "designData/sweepy.json"/>
<portfolioSegment jsonPath= "designData/mathematicalSeashell.json"/>
<portfolioSegment jsonPath= "designData/nestBuilding.json"/>
<portfolioSegment jsonPath= "designData/shrinkWrapStructure.json"/>
<portfolioSegment jsonPath= "designData/progressivePlywood.json"/>




<footerSegment buttonText = "back to the top" headerImage = ''></footerSegment>
</template>

<script>
import footerSegment from '@/components/footerSegment.vue' 
import portfolioSegment from '@/components/portfolioSegment.vue'
export default {
  name: 'portfolio',
  components: {
    portfolioSegment,
    footerSegment
  }
}
</script>
