<template>
    <div class = " mx-2 md:mx-32 my-2 px-2 md:px-8 py-2 md:py-2">
        <h1 class = "text-center md:text-left text-xl md:text-3xl text-stormy-darkGreen font-bold mb-0 md:mb-2">{{groupName}}</h1>
        <div class = "my-2 px-4 md:px-4 py-4 md:py-6 grid grid-cols-2 md:grid-cols-4 grid-flow-row lg:grid-flow-col auto-cols-fr gap-2 portfolioBg">
            <div v-for="skills in skillData" :key="skills" class = "rounded-md md:rounded-lg bg-stormy-green shadow-md flex  flex-col justify-center py-auto">
                <div v-if="skills.exampleImg !== null" class = "grid grid-cols-1 md:grid-cols-2">
                    <div>
                        <img v-if="skills.icon !== null" :src = "'/img/skillImg/'+ skills.icon" alt = "no image found"/>
                        
                    </div>
                    <div class = "p-2 md:p-4">
                        <img v-if="skills.exampleImg !== null" :src = "'/img/skillImg/' + skills.exampleImg" alt = "no image found"/>
                    </div>
                </div>
                <div v-else>
                    <div v-if="skills.name !== null">
                        <img v-if="skills.icon !== null" class = "p-2 md:p-4 flex m-auto content-center h-24 md:h-36" :src = "'/img/skillImg/'+ skills.icon" alt = "no image found"/>       
                        <h2 class = " mb-2 md:mb-4 w-full font-extralight md:text-xs lg:text-base text-stormy-lightGreen font-bold">{{skills.name}}</h2>
                    </div>
                    <div v-else>
                        <img v-if="skills.icon !== null" class = "p-2 md:p-4 flex m-auto content-center justify-center h-24 md:h-36" :src = "'/img/skillImg/'+ skills.icon" alt = "no image found"/>       
                    </div>
                    </div>
            </div>
        </div>
    </div>
</template>

<script>
export default{
    name:'skillSegment',
    props: ['jsonPath'],
    data(){
        return{
            skillData:[], groupName:"",
        };
    },
    mounted(){
         fetch('./json/skillData/' + this.jsonPath)
            .then(response => response.json())
            .then(data => {
                this.skillData = data.skills
                this.groupName = data.groupName
                });
}
}
</script>
