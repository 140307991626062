import { createRouter, createWebHistory } from 'vue-router'
import Home from '../pages/homePage.vue'
import GamePortfolio from '../pages/gamePortfolioPage.vue'
import DesignPortfolio from '../pages/designPortfolioPage.vue'
import Skills from '../pages/skillsPage.vue'
import Contact from '../pages/contactPage.vue'
import ThankYou from '../pages/thankYouPage.vue'
import Sorry from '../pages/sorryPage.vue'
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/gamePortfolio',
    name: 'Game Portfolio',
    component: GamePortfolio,
  },  
  {
    path: '/designPortfolio',
    name: 'Design Portfolio',
    component: DesignPortfolio,
  },
  {
    path: '/skills',
    name: 'Skills',
    component: Skills
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path:'/thank-you',
    name: 'ThankYou',
    component: ThankYou
  },
  {
    path: '/sorry',
    name: 'Sorry',
    component: Sorry
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
