<template> 
<headerSegment headerImage = "teri-logo.svg"/>
<h1 class = "text-4xl md:text-6xl font-bold text-center text-stormy-maroon rightTransitionIn"
data-replace = "{'translate-x-56': 'translate-x-0', 'opacity-0': 'opacity-100'}"></h1>
<h1 class = "mt-6 my-12 md:my-56 mx-12 flex md:flex-0 my-12 md:my-64 mx-12 md:mx-64 rightTransitionIn mb-5 m-auto content-center justify-center font-bold text-sm md:text-3xl text-stormy-maroon"
data-replace = "{'translate-x-56': 'translate-x-0', 'opacity-0': 'opacity-100'}"> Thank you! Your information has been submitted. I'll get back to you as soon as I can! </h1>
</template>

<script>
import headerSegment from '@/components/footerSegment.vue' 
export default {
  name: 'ThankYou',
  components: {
    headerSegment
  }
}
</script>