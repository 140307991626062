<template> 
<h1 class = "text-4xl md:text-6xl font-bold text-center text-stormy-maroon rightTransitionIn mt-12 md:mt-24 mb-4"
data-replace = "{'translate-x-56': 'translate-x-0', 'opacity-0': 'opacity-100'}"> SKILLS </h1>
<skillSegment jsonPath="programmingSkills.json"/>
<skillSegment jsonPath="gameDevSkills.json"/>
<skillSegment jsonPath="graphicSkills.json"/>
<skillSegment jsonPath="modellingSkills.json"/>
<footerSegment buttonText = "back to the top" headerImage = '' />
</template>

<script>
import footerSegment from '@/components/footerSegment.vue' 
import skillSegment from '@/components/skillSegment.vue'
export default {
  name: 'skills',
  components: {
    footerSegment,
    skillSegment
  }
}
</script>

<style>

</style>
