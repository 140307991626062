<template> 
<headerSegment headerImage = "teri-logo.svg"/>
<h1 class = "text-4xl md:text-6xl font-bold text-center text-stormy-maroon rightTransitionIn mt-6 mb-4"
data-replace = "{'translate-x-56': 'translate-x-0', 'opacity-0': 'opacity-100'}"> CONTACT ME </h1>
<contactSegment/>
</template>

<script>
import headerSegment from '@/components/footerSegment.vue' 
import contactSegment from '@/components/contactSegment.vue'
export default {
  name: 'Contact',
  components: {
    headerSegment,
    contactSegment
  }
}
</script>

<style>

</style>
