<template>
<div>
    <div class = "relative md:w-auto md:h-screen mx-16 my-4 md:mx-32 md:grid grid-cols-1 md:grid-cols-3 grid-row-2 place-items-center content-center md:gap-12">
        <div class = "mb-6 md:mb-0 leftTransitionIn "
                    data-replace = "{'-translate-x-56': 'translate-x-0', 'opacity-0': 'opacity-100'}">
            <img class = "rounded-full flex m-auto md:mx-0 content-center justify-center md:w-full w-1/2 scale-100 md:scale-90" 
            src="/img/me.jpg" alt="me.png not found"/>
            <div class = "mx-4 md:mx-12 grid grid-cols-2 grid-flow-col p-6 md:p-12 place-content-center gap-2">
            <a  href="https://www.linkedin.com/in/terence-lo-156602209/"  class = "relative m-auto content-center justify-center w-1/5 md:w-2/5" ><img src = "/img/linkedin.svg"/></a>
            <a href="https://twitter.com/teri_dev"  class = "relative m-auto content-center justify-center w-1/5 md:w-2/5"><img src = "/img/twitter.svg"/></a>
            </div>
        </div>
        <div class = " grid flex col-start-0 col-span-2">
            <div class = "topTransitionIn"
                data-replace = "{'-translate-y-56': 'translate-y-0', 'opacity-0': 'opacity-100'}">
                <p class = "homeBody">
                    Hey there! My name's <span class = "font-bold text-stormy-maroon">Terence</span> but you can call me Teri.
                </p>
                <p class = "homeBody">
                If you assumed I play video games.... <span class = "text-xs">well then you would be right.</span>
                </p>
                <p class = "homeBody">
                    I'm a twenty-three year old <span class = "font-bold text-stormy-maroon">Toronto-based game developer</span> looking to enter the 
                    industry as a Gameplay Programmer! I have a background studying Video Game Design and Development at Toronto Film School.
                </p>
                <p class = "homeBody">
                    I'm also currently working as an VR Developer for VR Vision Inc.
                </p>
                <p class = "homeBody">
                    If you want to get ahold of me, you can<span class = "text-stormy-maroon hover:text-stormy-orange font-semibold"><router-link to="/contact" > contact me here</router-link></span>.
                </p>
                </div>
                <div class = "mt-4 grid grid-cols-1 md:grid-cols-3 topTransitionInSlow"
                data-replace = "{'-translate-y-56': 'translate-y-0', 'opacity-0': 'opacity-100'}">
                    <div class = " my-4 md:my-auto homeBody text-xs md:text-xl text-center md:text-left content-around col-span-2" >
                        Otherwise, go ahead and scroll down to see my work or use the buttons to navigate.
                    </div>
                    <div class = "m-auto grid grid-cols-1 md:grid-cols3 justify-center">
                        <!--<router-link to="/portfolio">-->
                            <button @click="scrollToGameDev" class = "generalBtn my-2 py-auto px-10">
                            development projects
                            </button>
                        <!--</router-link>-->
                            <button @click="scrollToDesign" class = "generalBtn my-2 py-auto px-10">
                            design projects
                            </button>
                        <!--<router-link to="/skills">-->
                            <button @click="scrollToSkills" class = "generalBtn my-2 py-auto px-10">
                            skills
                            </button>
                        <!--</router-link>-->
                    </div>
            </div>

        </div>
        <div class = " mt-4 md:mt-auto col-span-3 animate-none w-4 md:w-8 mx-auto select-none"
        data-replace = "{'animate-none': 'animate-bounce'}">
            <img  src = "/img/down-chevron.png"/>
        </div>
  </div>
  </div>
</template>

<script>
export default {
    name: 'homeSegment',
    created(){
        setTimeout(
            function(){
                var replacers = document.querySelectorAll('[data-replace]');
                for(var i=0; i<replacers.length; i++){
                    let replaceClasses = JSON.parse(replacers[i].dataset.replace.replace(/'/g, '"'));
                    Object.keys(replaceClasses).forEach(function(key) {
                        replacers[i].classList.remove(key);
                        replacers[i].classList.add(replaceClasses[key]);
            });
        }
        }, 1)
    },
    methods: {
        scrollToSkills() {
            const el = this.$parent.$refs.skills;
            console.log(el);
            if (el) {
            // Use el.scrollIntoView() to instantly scroll to the element
            el.scrollIntoView({behavior: 'smooth'});
            }
        },
        scrollToDesign() {
            const el = this.$parent.$refs.design;
            console.log(el);
            if (el) {
            // Use el.scrollIntoView() to instantly scroll to the element
            el.scrollIntoView({behavior: 'smooth'});
            }
        },
        scrollToGameDev() {
            const el = this.$parent.$refs.gameDev;
            console.log(el);
            if (el) {
            // Use el.scrollIntoView() to instantly scroll to the element
            el.scrollIntoView({behavior: 'smooth'});
            }
        }
    }
}
</script>