<template> 

<h1 class = "text-4xl md:text-6xl font-bold text-center text-stormy-maroon rightTransitionIn mt-12 md:mt-12 mb-4"
data-replace = "{'translate-x-56': 'translate-x-0', 'opacity-0': 'opacity-100'}"> DEVELOPMENT </h1>
<h2 class = "text-xs italic font-light md:text-md text-center text-stormy-orange -mt-4 mb-2 md:mb-4">source code available upon request</h2>
<portfolioSegment jsonPath= "gameDevData/scotFree.json"/>
<portfolioSegment jsonPath= "gameDevData/floatLikeDandi.json"/>
<portfolioSegment jsonPath= "gameDevData/patrolPaths.json"/>
<portfolioSegment jsonPath= "gameDevData/website.json"/>
<portfolioSegment jsonPath= "gameDevData/constructionQueue.json"/>
<portfolioSegment jsonPath= "gameDevData/fiendsForgiven.json"/>
<portfolioSegment jsonPath= "gameDevData/SDL-Blackjack.json"/>
<footerSegment buttonText = "back to the top" headerImage = ''></footerSegment>
</template>

<script>
import footerSegment from '@/components/footerSegment.vue' 
import portfolioSegment from '@/components/portfolioSegment.vue'
export default {
  name: 'portfolio',
  components: {
    portfolioSegment,
    footerSegment
  }
}
</script>
