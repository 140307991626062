<template> 
<headerSegment headerImage = "teri-logo.svg"/>
<h1 class = "text-4xl md:text-6xl font-bold text-center text-stormy-maroon rightTransitionIn"
data-replace = "{'translate-x-56': 'translate-x-0', 'opacity-0': 'opacity-100'}"></h1>
<div>
<p class = "mt-6 my-12 md:my-56 mx-12 md:mx-80 rightTransitionIn mb-5 font-medium text-sm md:text-3xl text-stormy-maroon"
data-replace = "{'translate-x-56': 'translate-x-0', 'opacity-0': 'opacity-100'}"> Sorry, something seems to have gone wrong. If submission still doesn't work in a few minutes, 
you can contact me <a class = "font-extrabold hover:text-stormy-orange" href='https://www.linkedin.com/in/terence-lo-156602209/'>here</a>!
</p>
</div>
</template>

<script>
import headerSegment from '@/components/footerSegment.vue' 
export default {
  name: 'Sorry',
  components: {
    headerSegment
  }
}
</script>