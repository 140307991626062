<template>
    <div class = "mt-6 flex md:flex-0 mx-12 md:mx-32 leftTransitionIn mb-5 grid grid-cols-1 md:grid-cols-3"
    data-replace = "{'-translate-x-56': 'translate-x-0', 'opacity-0': 'opacity-100'}">
        <div class = "my-auto text-left content-center">
            <router-link to="/">
             <button @click="scrollToHome" class = "generalBtn flex md:flex-0 md:m-0 mx-auto mt-2 mb-4 md:my-auto content-center">
                 {{buttonText}}
                 </button> 
                 </router-link>
        </div>
        <div class = "m-auto">
            <img v-if="headerImage !== ''" class = "flex flex-col object-scale-down h-16" :src= "'./img/' + headerImage" alt = "headerImage not found"/>
            <!-- <span class = "mt-1 flex flex-col">
            <p class = "px-4 italic text-center"> terence </p>
            </span>
            <span class = "-m-2 flex flex-col">
            <p class = "italic text-center"> lo </p>
            </span> -->
        </div>
        <div>
        </div>
    </div>
</template>
<script>
export default{
    name: 'footerSegment',
    props: {
        headerImage:{
            type: String,
            required: false,
            default: "teri-logo.svg"
        },
        buttonText: {
            type: String,
            required: false,
            default: "to front page"
        }},
    data(){
        return{};
    }, 
    mounted(){
        setTimeout(
            function(){
                var replacers = document.querySelectorAll('[data-replace]');
                for(var i=0; i<replacers.length; i++){
                    let replaceClasses = JSON.parse(replacers[i].dataset.replace.replace(/'/g, '"'));
                    Object.keys(replaceClasses).forEach(function(key) {
                        replacers[i].classList.remove(key);
                        replacers[i].classList.add(replaceClasses[key]);
            });
        }
        }, 1);
    },
    methods:{
        scrollToHome() {
            const el = this.$parent.$parent.$refs.home;
            console.log(el);
            if (el) {
            // Use el.scrollIntoView() to instantly scroll to the element
            el.scrollIntoView({behavior: 'smooth'});
            }
        }
    }
}
</script>
